"use client";

import { APIError } from "@/lib/api/error";
import { cn } from "@/lib/utils";
import { Button } from "@primitives/button";
import { Card, CardBody } from "@primitives/card";
import { Text } from "@primitives/text";

export default function ErrorPage({
  error,
  reset,
  className,
}: {
  error: Error & { digest?: string };
  reset: () => void;
  className?: string;
}) {
  return (
    <Card
      className={cn(
        "bg-critical-subdued mx-auto mt-14 w-full max-w-[var(--max-width)] py-12",
        className,
      )}
    >
      <CardBody>
        <Text className="text-center" variant="bodySm" color="critical">
          The following error has occured!
        </Text>
        <Text className="mt-1 text-center" variant="headingMd">
          {APIError.parse(error.message)?.statusCode}:{" "}
          {APIError.parse(error.message)?.message}
          <br />
          {APIError.parse(error.message).customCode &&
            `Custom: ${APIError.parse(error.message)?.customCode}`}
        </Text>
        <Button
          className="mx-auto mt-4"
          theme="critical"
          onClick={() => reset()}
        >
          Try again
        </Button>
      </CardBody>
    </Card>
  );
}
